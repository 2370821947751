import {
  handleQueryResolve
} from '../utils'

export default function (patientID, drFirstName, drLastName, cpso) {
  if (patientID) {
    const where = []
    const attrs = []
    if (Array.isArray(patientID)) {
      where.push(`Rx.PatientID IN (${patientID.join(',')})`)
    } else {
      attrs.push({
        param: 'PatientID',
        type: 'sql.Int',
        value: patientID, // to support like
      })
      where.push('Rx.PatientID = @PatientID')
    }

    if (drFirstName) {
      attrs.push({
        param: 'drFirstName',
        type: 'sql.VarChar(50)',
        value: `%${drFirstName}%`, // to support like
      })

      where.push('doctor.FirstName LIKE @drFirstName')
    }

    if (drLastName) {
      attrs.push({
        param: 'drLastName',
        type: 'sql.VarChar(50)',
        value: `%${drLastName}%`, // to support like
      })

      where.push('doctor.LastName LIKE @drLastName')
    }

    if (cpso) {
      attrs.push({
        param: 'cpso',
        type: 'sql.VarChar(50)',
        value: `%${cpso}%`, // to support like
      })

      where.push('doctor.ID LIKE @cpso')
    }

    const query = `
    SELECT
            doctor.DoctorID,
            Patient.PatientID as PatientID,
            doctor.FirstName as DoctorFirstName,
            doctor.LastName as DoctorLastName,
            doctor.ID as CPSO,
            doctor.EMail as DoctorEmail,
            DoctorPhone.Phone as DoctorPhone,
            DoctorFax.Phone as DoctorFax,
            DoctorAddress.Address as DoctorAddress,
            DoctorAddress.City as DoctorCity,
            DoctorAddress.Province as DoctorProvince,
            DoctorAddress.Postal as DoctorPostal,
            DoctorAddress.Address+DoctorPhone.Phone+DoctorFax.Phone as DoctorSelectId,
            COUNT(doctor.DoctorID) as RXperProvider
    FROM
            Fillware.dbo.Rx as rx
    INNER JOIN
            Fillware.dbo.Doctor as doctor ON doctor.DoctorID = rx.DoctorID
    INNER JOIN
            Fillware.dbo.DoctorAddress as DoctorAddress ON doctor.DoctorID = DoctorAddress.DoctorID AND DoctorAddress.DefaultAddress = 1
    INNER JOIN
            Fillware.dbo.RxExtended as RxExtended on RxExtended.RxNumber = Rx.RxNumber
    INNER JOIN
            Fillware.dbo.Patient ON Rx.PatientID = Patient.PatientID
    LEFT JOIN
            Fillware.dbo.DoctorPhone as DoctorFax ON DoctorFax.AddressID = RxExtended.DoctorAddressID AND DoctorFax.PhoneType = 'FAX'
    LEFT JOIN
            Fillware.dbo.DoctorPhone as DoctorPhone ON DoctorPhone.AddressID = RxExtended.DoctorAddressID AND DoctorPhone.PhoneType = 'BUSINESS'
    WHERE
            rx.RxDate > DATEADD(year, -1, GetDate())
    AND
        ${where.join(' AND ')}
    GROUP BY
            doctor.DoctorID,
            Patient.PatientID,
            doctor.FirstName,
            doctor.LastName,
            doctor.EMail,
            doctor.ID,
            DoctorFax.Phone,
            DoctorPhone.Phone,
            DoctorAddress.Address,
            DoctorAddress.City,
            DoctorAddress.Province,
            DoctorAddress.Postal
    ORDER BY RXperProvider DESC
    `

    // console.log(query)

    return this.query(query, attrs).then(handleQueryResolve)
  }
}
